<template lang="pug">
  section.choose
    .choose__container
      .choose__top
        .choose__img
          img(
            :src="ChooseSection"
            alt="choose section"
          )
        .choose__content
          h2.choose__title.title
            | Чому обирають
            |
            span VIDAR
            | ?
          v-collapse-group.choose__spollers(:only-one-active="true")
            v-collapse-wrapper.choose__spoller(
              v-for="(item, index) in collapsItems"
              :key="index"
            )
              .choose__body-spoller(v-collapse-content)
                .choose__text-spoller
                  p {{ item.collapsText }}
              .choose__title-spoller(v-collapse-toggle)
                h5 {{ item.collapsTitle }}
                span.choose__arrow
                  img(
                    :src="BlackArrowDown"
                    alt="black arrow down"
                  )

      .choose__bottom
        ul.choose__list
          li.choose__item(
            v-for="(item, index) in chooseList"
            :key="index"
          )
            span.choose__counter-item {{ item.chooseCount }}
            p.choose__text-item {{ item.chooseText }}
</template>

<script>
// images
import BlackArrowDown from '@/assets/images/arrow-spoller.svg'
import ChooseSection from '@/assets/images/home-page/choose-section/01.jpg'
export default {
  name: 'ChooseBlock',
  data () {
    return {
      BlackArrowDown,
      ChooseSection,
      collapsItems: [
        {
          collapsText: 'Ми навчаємо вихованців встановлювати власні цілі та покроково до них приходити. При цьому приділяємо увагу самопочуттю та комфорту власного тіла.',
          collapsTitle: 'Унікальна методика викладання'
        },
        {
          collapsText: 'У стінах «VIDAR» немає місця зневазі, байдужості чи злості. На заняттях панує взаєморозуміння між вихованцями і тренером, знаходяться нові знайомства.',
          collapsTitle: 'Дружня атмосфера тренувань'
        },
        {
          collapsText: 'Наші учні проходять інструктажі з правил безпеки і поведінки із спеціальним спортивним обладнанням. Кількість учнів у групі дає змогу приділяти кожному рівноцінну увагу.',
          collapsTitle: 'Безпечне проведення занять'
        },
        {
          collapsText: 'Усі наші тренери мають професійну кваліфікацію і викладацьку освіту. Багатороковий досвід допомогає виховувати найкращих спортсменів.',
          collapsTitle: 'Команда професійних тренерів'
        },
        {
          collapsText: 'У наших залах встановлено сучасне і сертифіковане спортивне обладнання. Просторі зали, вентильовані чисті приміщення і душові кімнати облаштовані для комфортних занять спортом.',
          collapsTitle: 'Інфраструктура для повноцінних тренувань'
        }
      ],
      chooseList: [
        {
          chooseCount: '90',
          chooseText: 'Хвилин тривалість тренування'
        },
        {
          chooseCount: '3-6',
          chooseText: 'Занять на день'
        },
        {
          chooseCount: '10-15',
          chooseText: 'Дітей у групі'
        },
        {
          chooseCount: '6',
          chooseText: 'Тренерів з професійною освітою'
        }
      ]
    }
  }
}
</script>
